h1{
    color: $c3;
}
section{
    min-height: 200px;
}
p{
    font-size: 1.2rem;
}
.text-dark-blue{
    color: #204e4a!important;
}
.serif{
    font-family: serif;
}

.banner{
    padding: .5rem;
    padding-bottom: 0;
    min-height: 200px;
    position: relative;
}
.banner img{
    position: absolute;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 55vw;
    right: 8%;
    bottom: -30px;
}
.sub-page.banner img{
    max-height: 150%;
    max-width: 75vw;
    right: 8%;
    bottom: -0px;
}
.banner-content{
    width: 50vw;
    max-width: 543px;
    margin-left: 0px;
    margin-top: 70px;
    margin-bottom: 100px;
    padding: 1.4rem;
    position: relative;
}
.sub-page .banner-content{
    width: 60vw;
    max-width: 543px;
    margin-left: 20px;
    margin-top: 50px;
    background-color: rgba(255,255,255,.5)!important;
    background: rgba(255,255,255,.5)!important;
}
.banner-content h1{
    font-size: 1.6rem;
    font-family: serif;
}
.banner-content p{
    font-size: 1.1rem;
}
.lift{
  position: relative;
  top: 3px;
}
.search-block{
  padding-top: -100px;
    width: 100%;
    position: relative;
}

.search-block-sticky{
    position: sticky;
    top: 6px;
    margin-top: -32px;
}
.search-form{
    background-color: $c3;
    background-color: #fff;
    height: auto;
    min-height: 100px;
    width: 85%;
    max-width: 1111px;
}
// .search-form .form-control{
.form-control{
    color: $c3!important;
    transition: border-color .1s, border-width .1s;

    &:active,&:focus{
        border-color: rgba(234,234,234,.9);
        border-bottom: .3rem solid $c2;
        box-shadow: none;
        outline: none;
    }
}
.search-block-sticky .search-form{
    width: 90%;
}

.show-extended-search-button{
    color: #fff!important;
    border: none!important;
    &:active,&:focus{
        border: none!important;
        outline: none!important;
        box-shadow: none!important;
    }
    i{
        font-size: .8rem;
        vertical-align: middle;
        padding-bottom: 2px;
    }
}
.map-image{
    max-height: 456px;
}
.service-list-provider-logo{
    height: auto;
    width: auto;
    max-height: 100px;
    max-width: 100%;
}
.provider-list-provider-logo{
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}
.provider-list-provider-logo-wrap{
  max-height: 100px;
}
.selected-service{
  border-left: 5px solid $c2;
}
#filters{
    i{
        padding-top: 3px;
        font-size: .7rem;
        vertical-align: middle;
        font-weight: 100;
    }
}
#map { height: 420px; }

.result-list-wrap{
    min-height: 234vh;
}
.provider-image{
  min-height: 320px;
  max-height: 320px;
  height: 310px;
  background-size: cover;
  background-position: center center;
}
.ui-widget-content.ui-autocomplete.ui-front{
    z-index: 999999;
    max-height: 543px;
    max-width: clamp(200px,678px,80vw);
    overflow-y: auto;   /* prevent horizontal scrollbar */
    overflow-x: hidden; /* add padding to account for vertical scrollbar */
    -webkit-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
       -moz-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
            box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
}
.latest-service .img-wrap{
    height: 150px!important;
    text-align:center;

}
.latest-service .img-wrap img{
    height: auto;
    width: auto;
    object-fit: cover;

}
.mh-100{
    min-height: 100vh;
}
.mh-600{
    min-height: 600px;
}
.section-bg-img{
    position: absolute;
    bottom: 0;
    right: 0;
}
@media (min-width: 768px) {
    .banner-content{
        width: 50vw;
        max-width: 543px;
        margin-left: 60px;
    }
    .banner-content h1{
        font-size: 2rem;
        font-family: serif;
    }
    .banner-content p{
        font-size: 1.4rem;
    }
    .lift{
      position: relative;
      top: -23px;
    }
}
@media (min-width: 992px) {
    .banner-content{
        width: 50vw;
        max-width: 543px;
        margin-left: 100px;
    }
}
