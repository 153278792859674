html,body{
    width: 100vw!important;
    height: 100%!important;
    padding: 0!important;
    margin: 0!important;
    scrollbar-gutter: stable!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px !important;
    position: relative;
}
html{
    overflow-x: hidden;
}
p::selection,h1::selection ,h2::selection ,a::selection,*::selection {
  background:$c3; /* WebKit/Blink Browsers */
  color: #fff;
}
p::-moz-selection,h1::-moz-selection ,h2::-moz-selection ,a::-moz-selection,*::-moz-selection {
  background: $c3; /* Gecko Browsers */
  color: #fff;
}
section{
    position: relative;
}
i{
    transition: transform .22s;
}
.wrap{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.sticky-top{
    position: sticky;
    top: 0;
    width: 100%;
}
.bg-slim{
    height: 300px;
    background-color: $c1;
}
.z-50{
    z-index: 50;
}
.z-100{
    z-index: 100;
}
.w-85{
    width: 85%;
}
.w-90{
    width: 90%!important;
}
.rotate-180{
    transform: rotate(-180deg);
}
.fz2r{
    font-size: 2rem!important;
}
.fz1r{
    font-size: 1rem!important;
}
.container{
    max-width: 1400px;
}
.shadow-io
{
-webkit-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
-moz-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
}
.shadow-o
{
    -webkit-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
       -moz-box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
            box-shadow:0 .1rem .3rem rgba(0, 0, 0, 0.3);
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: $c2;
  background-color: $c2_light;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem $c2_transparent;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $c2_semi;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: $c2_transparent;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: $c2_semi;
}

.custom-select:focus {
  border-color: $c2_transparent;
  box-shadow: 0 0 0 0.2rem $c2_semi;
}

@media (min-width: 768px) {
  .collapse.dont-collapse-md {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.bg-img-top{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
}

.page-logo{
    height: 95%;
    width: auto;
    position: absolute;
    color: $c2_semi;
    z-index: 5;
    top: -5px;
    left: 105px;
}
.navbar-brand i{
    font-size: 50px;
}
.navbar-brand span{
    line-height: 20px;
    z-index: 6;
}
.nav-link{
    font-weight: 500;
    &:hover{
        color: $c3!important;
    }
}
.nav-link.active{
    color: $c2_dark!important;
}

.btn-outline-c2{
    color: $c2!important;
    border-color: $c2!important;
    font-weight: bolder!important;
    &:hover{
        color: #000!important;
        background-color: $c2!important;
        border-color: $c2!important;
    }
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem $c2_semi;
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: $c2;
  border-color: $c2;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem $c2_semi;
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: $c2;
  background-color: transparent;
}

.btn-rounded{
    border-radius: 9999px;
    padding: .2rem 1.2rem!important;
}
.btn-link{
    font-weight: bold!important;
}
.btn-c2{
    color: #000;
    background-color: $c2;
    border-color: $c2_dark;
    &:hover{
        background-color: $c2_light;
    }
}
.btn-primary{
    background-color: $c1;
    border-color: $c1_light!important;
    &:hover,&:focus,&:active{
        background-color: $c1_dark!important;
        border-color: $c1_light!important;
        outline: none!important;
        box-shadow: none!important;
    }
}

.spinner {
  color: $c2_light;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
  position: absolute;
}

.spinner:after {
  animation: changeContent 1s linear infinite;
  display: block;
  content: "⠋";
  font-size: 80px;
}

@keyframes changeContent {
  10% { content: "⠙"; }
  20% { content: "⠹"; }
  30% { content: "⠸"; }
  40% { content: "⠼"; }
  50% { content: "⠴"; }
  60% { content: "⠦"; }
  70% { content: "⠧"; }
  80% { content: "⠇"; }
  90% { content: "⠏"; }
}

.leaflet-popup-content{
  max-width: 180px!important;
}
@media screen and (min-width: 786px) {
  .map-wrap.sidebar #map{
    transition: margin-left .6s ease .3s;
    &:hover{
      margin-left: -321px;
     box-shadow:0 -.1rem .3rem rgba(0, 0, 0, 0.3);
     transition: margin-left .6s ease ;
    }
  }
}

.underline-50-wrap{
  width: 100%;
  height: auto;
}
.underline-50-wrap .headline{
  position: relative;
  width: 100%;
  margin-bottom: .5rem;
  margin-top: 1rem;
  margin-left: -.1rem;
  transition: color .3s ease;
}
.underline-50-wrap .headline.active{
  color: $c2_dark!important;
}
.underline-50-wrap .headline::after,
.underline-50-wrap .headline::before{
  content: '';
  position: absolute;
  height: .1rem;
  background-color: #ccc;
  width: 340px;
  bottom: 0;
  left: 0;
  transition: width 1s ease;
}
.underline-50-wrap .headline::after{
  width: 0px;
  transition: width 1s ease;
}
.underline-50-wrap .headline.active::after{
  transition: width 1s ease;
  content: '';
  position: absolute;
  height: .1rem;
  background-color: $c2_dark;
  width: 340px;
  bottom: 0;
  left: 0;
}
.scrollspy-nav{
  position: sticky;
  top: 0;
  z-index: 99999;
  margin-right: -30px;
  margin-left: -15px;
  width: calc(100% + 30px);
}
.scrollspy-nav .nav-link:focus,
.scrollspy-nav .nav-link:active{
}
.scrollspy-nav .nav-link.active{
  background: #fff;
  color: $c2!important;
}
.list-group-item-hover .fa-map-marker{
  transition: color .2s;
}
.list-group-item-hover{
  transition: border-color .2s;
}
.list-group-item-hover:hover{
  border-color: $c2;
}
.list-group-item-hover:hover .fa-map-marker{
  color: $c2!important;
}
footer{
  margin-top: 12rem!important;
}
.page-link {
  color: $c3_light;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  color: $c3_dark;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.page-item.active .page-link {
  color: $c3_dark;
  background-color: $c2_transparent;
  border-color: $c2_transparent;
}
.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  // background-color: $c2_transparent;
  border-color: #dee2e6;
}
.toTopBtn{
  position: sticky;
  margin-top: 100vh;
  // left: 4rem;
  top: 90vh;
  z-index: 9999;
}
@media (max-height: 432px) {
  .scrollspy-nav{
    display: none!important;
  }
}