// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~jquery-ui/themes/base/all.css';


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import 'style';
@import 'frontpage';
