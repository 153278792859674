// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;



$c1_light: #7ab4a7;
$c1: #6aab9c;
$c1_dark: #6ba191;
$c1_semi: rgba(106, 171, 156, .5);
$c1_transparent: rgba(106, 171, 156, .2);

$c2_light: #f8bf83;
$c2: #f7b36b;
$c2_dark: #f6a753;
$c2_semi: rgba(247, 179, 107, .5);
$c2_transparent: rgba(247, 179, 107, .2);


$c3_light: #27605b;
$c3: #204e4a;
$c3_dark: #193c39;
$c3_semi: rgba(32, 78, 74, .5);
$c3_transparent: rgba(32, 78, 74, .2);

.bg-c1{ background-color: $c1!important; }
.bg-c1-dark{ background-color: $c1_dark!important; }
.bg-c2{ background-color: $c2!important; }
.bg-c2-transparent{ background-color: $c2_transparent!important; }
.bg-c2-light{ background-color: $c2_light!important; }
.bg-c2-dark{ background-color: $c2_dark!important; }
.bg-c3-light{ background-color: $c3_light!important; }
.bg-c3{ background-color: $c3!important; }
.bg-c3-dark{ background-color: $c3_dark!important; }
.c1{ color: $c1!important; }
.c1-dark{ color: $c1_dark!important; }
.c2{ color: $c2!important; }
.c2-dark{ color: $c2_dark!important; }
.c3{ color: $c3!important; }
